import { TableColumn, TableProps } from '@backstage/core-components';
import { CatalogTable, CatalogTableRow } from '@backstage/plugin-catalog';
import { useEntityList } from '@backstage/plugin-catalog-react';
import React from 'react';
import { bongoCatalogColumns } from './BongoCatalogColumns';


interface BongoCatalogTableProps {
  columns?: TableColumn<CatalogTableRow>[];
  actions?: TableProps<CatalogTableRow>['actions'];
  tableOptions?: TableProps<CatalogTableRow>['options'];
}

/**
 * Extensions of the backstage's CatalogTable which overrides the `columns`
 * prop when it is a bongo-defined component.
 * 
 */
export const BongoCatalogTable = (props: BongoCatalogTableProps) => {
  const {
    columns,
    actions,
    tableOptions
  } = props;
  const { filters } = useEntityList();
  const bongoColumns = bongoCatalogColumns(filters.kind?.value);

  return (
    <CatalogTable
      columns={bongoColumns || columns}
      actions={actions}
      tableOptions={tableOptions}
    />
  );
}