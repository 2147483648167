import React from 'react';
import { Grid } from '@material-ui/core';
import { parseEntityRef } from '@backstage/catalog-model';
import { EntityTable, EntityRefLink } from '@backstage/plugin-catalog-react';
import { TableColumn } from '@backstage/core-components';
import { InstanceEntityV1, RELATION_DEPLOYMENT_OF } from '@internal/plugin-bongo-common';
import { BongoRelatedEntitiesCard } from '../BongoRelatedEntitiesCard';
import { EntityRefAttribute } from '../EntityRefAttribute';

/**
 *  @public
 */
export const DeploymentEntityDeploymentOfCard = () => {
  const instanceInstanceOf: TableColumn<InstanceEntityV1> = {
    render: entity => (<EntityRefLink
      entityRef={parseEntityRef(entity.spec.instanceOf)}
    />),
    title: 'Instance Of'
  }

  const instanceType: TableColumn<InstanceEntityV1> = {
    render: (entity) => (
      <EntityRefAttribute
        entityRef={parseEntityRef(entity.spec.instanceOf)}
        attributePath="spec.type"
      />
    ),
    title: 'Type'
  }


  const instanceEntityColumns: TableColumn<InstanceEntityV1>[] = [
    EntityTable.columns.createEntityRefColumn({ defaultKind: 'instance' }),
    EntityTable.columns.createMetadataDescriptionColumn(),
    instanceInstanceOf,
    instanceType,
  ];

  return (<Grid item>
    <BongoRelatedEntitiesCard
      variant="gridItem"
      title="Instances Deployed"
      entityKind="Instance"
      relationType={RELATION_DEPLOYMENT_OF}
      columns={instanceEntityColumns}
      asRenderableEntities={(entities: any[]) => entities as InstanceEntityV1[]}
      emptyMessage="No instances are deployed by this deployment"
      emptyHelpLink="nolink" // FIXME: add the link to the example for "deploys" attribute for Deployment
      paging
      search
    />
  </Grid>);
};
