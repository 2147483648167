import {
  Entity,
  entityKindSchemaValidator,
  KindValidator,
} from '@backstage/catalog-model';
import schema from '../schema/Deployment.v1.schema.json';

interface DeployedEntity {
  name: string;
  instanceOf: string;
  [additionalAnnotation: string]: string;
}

/**
 * Backstage catalog Component kind Deployment. A deployment is an operation performed by the build/release pipeline to publish a system to an environment.
 *
 * @remarks
 *
 * See {@link https://backstage.io/docs/features/software-catalog/system-model}
 *
 * @public
 */
export interface DeploymentEntityV1 extends Entity {
  apiVersion: 'bongo/v1';
  kind: 'Deployment';
  spec: {
    time: string;
    environment: string;
    version?: string;
    repository?: string;
    branch?: string;
    revision?: string;
    build?: string;
    outcome?: string;
    deploys: DeployedEntity[];
  };
}

const validator = entityKindSchemaValidator(schema);

/**
 * Entity data validator for {@link deploymentEntityV1}.
 *
 * @public
 */
export const deploymentEntityV1Validator: KindValidator = {
  // TODO(freben): Emulate the old KindValidator until we fix that type
  async check(data: Entity) {
    return validator(data) === data;
  },
};
