import React from 'react';
import { Grid } from '@material-ui/core';
import { RELATION_HAS_MEMBER } from '@backstage/catalog-model';
import { EntityTable } from '@backstage/plugin-catalog-react';
import { TableColumn } from '@backstage/core-components';
import { EnvironmentEntityV1 } from '@internal/plugin-bongo-common';
import { BongoRelatedEntitiesCard } from '../BongoRelatedEntitiesCard';

/**
 *  @public
 */
export const EnvironmentGroupEntityHasMembersCard = () => {
  const environmentAWSAccount: TableColumn<EnvironmentEntityV1> = {
    render: (entity) => entity.spec.aws_account || 'Unknown',
    title: 'AWS Account'
  }

  const environmentAWSRegion: TableColumn<EnvironmentEntityV1> = {
    render: (entity) => entity.spec.aws_region || 'Unknown',
    title: 'AWS Region'
  }

  const environmentStage: TableColumn<EnvironmentEntityV1> = {
    render: (entity) => entity.spec.stage || 'Unknown',
    title: 'Stage'
  }
  
  const environmentEntityColumns: TableColumn<EnvironmentEntityV1>[] = [
    EntityTable.columns.createEntityRefColumn({ defaultKind: 'environment' }),
    EntityTable.columns.createMetadataDescriptionColumn(),
    environmentAWSAccount,
    environmentAWSRegion,
    environmentStage
  ];

  return (<Grid item>
    <BongoRelatedEntitiesCard
      variant="gridItem"
      title="Environments"
      entityKind="Environment"
      relationType={RELATION_HAS_MEMBER}
      columns={environmentEntityColumns}
      asRenderableEntities={(entities: any[]) => entities as EnvironmentEntityV1[]}
      emptyMessage="No environments are members of this EnvironmentGroup"
      emptyHelpLink="nolink" // FIXME: add the link to the example for "environment" attribute for instance
      paging={false}
      search={false}
    />
  </Grid>);
};
