import React from 'react'
import { AboutField } from '@backstage/plugin-catalog';
import { EntityRefLink, useEntity } from '@backstage/plugin-catalog-react';
import { DeploymentEntityV1 } from '@internal/plugin-bongo-common';
import { parseEntityRef } from '@backstage/catalog-model';
import { BongoAboutField } from './BongoAboutField';

export const DeploymentEntityAboutCardContent = () => {
  const { entity } = useEntity();

  return (
    <>
      <BongoAboutField
        label="Time"
        value={new Date((entity as DeploymentEntityV1).spec.time).toLocaleString()}
      />

      <AboutField label="Environment" gridSizes={{ xs: 12, sm: 6, lg: 4 }}>
        <EntityRefLink
          entityRef={parseEntityRef((entity as DeploymentEntityV1).spec.environment, {
            defaultKind: 'environment'
          })}
        />
      </AboutField>

      <BongoAboutField
        label="Version"
        emptyValue="No version"
        value={(entity as DeploymentEntityV1).spec.version}
      />

      <BongoAboutField
        label="Repository"
        emptyValue="No repository"
        value={(entity as DeploymentEntityV1).spec.repository}
      />

      <BongoAboutField
        label="Branch"
        emptyValue="No branch"
        value={(entity as DeploymentEntityV1).spec.branch}
      />

      <BongoAboutField
        label="Revision"
        emptyValue="No revision"
        value={(entity as DeploymentEntityV1).spec.revision}
      />

      <BongoAboutField
        label="Build"
        emptyValue="No build"
        value={(entity as DeploymentEntityV1).spec.build}
      />

      <BongoAboutField
        label="Outcome"
        emptyValue="No outcome"
        value={(entity as DeploymentEntityV1).spec.outcome}
      />
    </>
  );
}