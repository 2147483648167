import React from 'react'
import { AboutField } from '@backstage/plugin-catalog';
import { EntityRefLink, useEntity } from '@backstage/plugin-catalog-react';
import { EnvironmentEntityV1 } from '@internal/plugin-bongo-common';
import { parseEntityRef } from '@backstage/catalog-model';
import { BongoAboutField } from './BongoAboutField';

export const EnvironmentEntityAboutCardContent = () => {
  const { entity } = useEntity();

  return (
    <>
      <BongoAboutField
        label="Aliases"
        emptyValue="No aliases"
        value={(entity as EnvironmentEntityV1).spec.aliases}
      />

      <BongoAboutField
        label="Domain"
        emptyValue="No domain"
        value={(entity as EnvironmentEntityV1).spec.domain}
      />

      <BongoAboutField
        label="Additional Domains"
        emptyValue="No additional domain"
        value={(entity as EnvironmentEntityV1).spec.additional_domains}
      />

      <BongoAboutField
        label="Stage"
        emptyValue="No stage"
        value={(entity as EnvironmentEntityV1).spec.stage}
      />

      <BongoAboutField
        label="AWS Account"
        emptyValue="No aws account"
        value={(entity as EnvironmentEntityV1).spec.aws_account}
      />

      <BongoAboutField
        label="AWS Region"
        emptyValue="No aws region"
        value={(entity as EnvironmentEntityV1).spec.aws_region}
      />

      <AboutField label="Instance Of" gridSizes={{ xs: 12, sm: 6, lg: 4 }}>
        <EntityRefLink
          entityRef={parseEntityRef((entity as EnvironmentEntityV1).spec.instanceOf, {
            defaultKind: 'domain'
          })}
        />
      </AboutField>
    </>
  );
}