import React from 'react';
import { Grid } from '@material-ui/core';
import { RelatedEntitiesCard } from '@backstage/plugin-catalog';
import { EntityTable } from '@backstage/plugin-catalog-react';
import { TableColumn } from '@backstage/core-components';
import { DeploymentEntityV1, RELATION_TARGET_OF } from '@internal/plugin-bongo-common';

const timeColumn: TableColumn<DeploymentEntityV1> = {
  render: entity => new Date(entity.spec.time).toLocaleString(),
  title: 'Deployed At',
  width: 'auto'
}

const deploymentEntityColumns: TableColumn<DeploymentEntityV1>[] = [
  EntityTable.columns.createEntityRefColumn({ defaultKind: 'deployment' }),
  EntityTable.columns.createMetadataDescriptionColumn(),
  timeColumn
];

export const EntityTargetOfCard = () => {
  return (<Grid item>
    <RelatedEntitiesCard
      variant="gridItem"
      title="Targeted By"
      entityKind="Deployment"
      relationType={RELATION_TARGET_OF}
      columns={deploymentEntityColumns}
      asRenderableEntities={(entities: any[]) => entities as DeploymentEntityV1[]}
      emptyMessage="No deployments target this environment"
      emptyHelpLink="nolink" // FIXME: add the link to the example for a deployment
    />
  </Grid>);
};
