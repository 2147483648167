import { InstanceEntityV1 } from '../kinds/InstanceEntityV1';

export const instanceEntityV1Example: InstanceEntityV1 = {
  'apiVersion': 'bongo/v1',
  'kind': 'Instance',
  'metadata': {
    'name': 'bongo-php-backend-bongo-na-22.4',
    'description': 'Bongo php backen for 22.4 deployed to bongo-na.',
    'annotations': {
      'aws-arn': 'arn:123:456',
      'hello': 'world'
    }
  },
  'spec': {
    'instanceOf': 'component:bongo-php-backend',
    'environment': 'bongo-na',
    'deployment': 'deployment-22.4'
  }
}