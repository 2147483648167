import { DeploymentEntityV1 } from '../kinds/DeploymentEntityV1';

export const deploymentEntityV1Example: DeploymentEntityV1 = {
  'apiVersion': 'bongo/v1',
  'kind': 'Deployment',
  'metadata': {
    'name': 'deployment-22.4',
    'description': 'Deployment of bongo 22.4'
  },
  'spec': {
    'time': '2022-05-04T14:22:00Z',
    'environment': 'bongo-na',
    'version': '22.4',
    'repository': 'https://github.com/YouSeeU/bongo',
    'branch': 'master',
    'revision': '3dbcb2001ffa4af770c9d0a07fb7ecd8a7e3a82d',
    'build': 'https://gitlab.com/bongolearn/services/bongo-standalone/-/jobs/2414720981',
    'outcome': 'success',
    'deploys': [
      {
        'name': 'bongo-php-backend-22.4',
        'instanceOf': 'component:bongo-php-backend',
        'aws-arn': 'arn:123:456:786',
        'other-annotation': 'hello world'
      }
    ]
  }
}