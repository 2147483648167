import {
  Entity,
  entityKindSchemaValidator,
  KindValidator,
} from '@backstage/catalog-model';
import schema from '../schema/Instance.v1.schema.json';

/**
 * Backstage catalog Component kind Instance.An instance is a realization (live, functional) existence of a component, resource, system, or API.
 *
 * @remarks
 *
 * See {@link https://backstage.io/docs/features/software-catalog/system-model}
 *
 * @public
 */
export interface InstanceEntityV1 extends Entity {
  apiVersion: 'bongo/v1';
  kind: 'Instance';
  spec: {
    instanceOf: string;
    environment?: string;
    deployment?: string;
  };
}

const validator = entityKindSchemaValidator(schema);

/**
 * Entity data validator for {@link InstanceEntityV1}.
 *
 * @public
 */
export const instanceEntityV1Validator: KindValidator = {
  // TODO(freben): Emulate the old KindValidator until we fix that type
  async check(data: Entity) {
    return validator(data) === data;
  },
};
