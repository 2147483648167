import React, { useEffect, useState } from 'react';
import { parseEntityRef, CompoundEntityRef } from '@backstage/catalog-model';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { useApi } from '@backstage/core-plugin-api';
import { get } from 'lodash';

interface EntityRefAttributeProps {
    entityRef: CompoundEntityRef;
    attributePath: string;
}

/**
 * Gets an attribute from an entityref and returns it as a React Component.
 * Useful for rendering inside of table columns.
 */
export const EntityRefAttribute = (props: EntityRefAttributeProps) => {
    const catalogClient = useApi(catalogApiRef);
    const { entityRef, attributePath } = props;
    const [attribute, setAttribute] = useState('Unknown');

    useEffect(() => {
        const updateType = async () => {
            const instanceOfEntityRef = parseEntityRef(entityRef);
            const instanceOfEntity = await catalogClient.getEntityByRef(instanceOfEntityRef);
            setAttribute(get(instanceOfEntity, attributePath, 'Unknown') as string);
        };
        updateType();
    }, [entityRef, attributePath, catalogClient])
    return <>{attribute}</>;
}