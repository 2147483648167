import { Entity } from '@backstage/catalog-model';
import { makeStyles } from '@material-ui/core';
import React, { ReactNode } from 'react';
import { columnFactories } from '@backstage/plugin-catalog-react';
import {
  InfoCardVariants,
  Table,
  TableColumn,
} from '@backstage/core-components';

/**
 * Props for {@link EntityTable}.
 *
 * @public
 */
export interface BongoEntityTableProps<T extends Entity> {
  title: string;
  variant?: InfoCardVariants;
  entities: T[];
  emptyContent?: ReactNode;
  columns: TableColumn<T>[];
  paging: boolean;
  search: boolean;
}

const useStyles = makeStyles(theme => ({
  empty: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
  },
}));

/**
 * A more general table component to be used for some of Bongo's use cases. This is really just ripped from
 * backstage's {@link EntityTable} component.
 * Source for backstage's {@link EntityTable} is [here](https://github.com/backstage/backstage/blob/master/plugins/catalog-react/src/components/EntityTable/EntityTable.tsx)
 *
 *
 * @public
 */
export const BongoEntityTable = <T extends Entity>(props: BongoEntityTableProps<T>) => {
  const {
    entities,
    title,
    emptyContent,
    variant = 'gridItem',
    columns,
    paging,
    search
  } = props;

  const classes = useStyles();
  const tableStyle: React.CSSProperties = {
    minWidth: '0',
    width: '100%',
  };

  if (variant === 'gridItem') {
    tableStyle.height = 'calc(100% - 10px)';
  }

  return (
    <Table<T>
      columns={columns}
      title={title}
      style={tableStyle}
      emptyContent={
        emptyContent && <div className={classes.empty}>{emptyContent}</div>
      }
      options={{
        // TODO: Toolbar padding if off compared to other cards, should be: padding: 16px 24px;
        search,
        paging,
        actionsColumnIndex: -1,
        padding: 'dense',
      }}
      data={entities}
    />
  );
};

BongoEntityTable.columns = columnFactories;
