import { EnvironmentGroupEntityV1 } from '../kinds/EnvironmentGroupEntityV1';

export const environmentGroupEntityV1Example: EnvironmentGroupEntityV1 = {
  'apiVersion': 'bongo/v1',
  'kind': 'EnvironmentGroup',
  'metadata': {
    'name': 'production',
    'description': 'Production environment group.'
  },
  'spec': {
    'members': [
      'bongo',
      'bongo-na',
      'bongo-eu',
      'bongo-au',
      'bongo-sa'
    ]
  }
}