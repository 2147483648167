import { parseEntityRef } from '@backstage/catalog-model';
import { TableColumn } from '@backstage/core-components';
import { CatalogTable, CatalogTableRow } from '@backstage/plugin-catalog';
import { EntityRefLink } from '@backstage/plugin-catalog-react';
import { DeploymentEntityV1, InstanceEntityV1 } from '@internal/plugin-bongo-common';
import React from 'react';

const createEnvironmentEntitySpecificColumns = (): TableColumn<CatalogTableRow>[] => {
  return [
    {
      title: 'Domain',
      field: 'entity.spec.domain'
    },
    {
      title: 'Stage',
      field: 'entity.spec.stage'
    },
    {
      title: 'AWS Account',
      field: 'entity.spec.aws_account'
    },
    {
      title: 'AWS Region',
      field: 'entity.spec.aws_region'
    }
  ];
}

const createDeploymentEntitySpecificColumns = (): TableColumn<CatalogTableRow>[] => {
  return [
    {
      title: 'Time',
      render: ({ entity }) => new Date((entity as DeploymentEntityV1).spec.time).toLocaleString()
    },
    {
      title: 'Environment',
      render: ({ entity }) => (entity as InstanceEntityV1).spec.environment ? (
        <EntityRefLink
          entityRef={parseEntityRef((entity as InstanceEntityV1).spec.environment as string, {
            defaultKind: 'environment'
          })}
          defaultKind="environment"
        />
      ) : undefined,
    },
    {
      title: 'Version',
      field: 'entity.spec.version'
    }
  ];
}

const createInstanceEntitySpecificColumns = (): TableColumn<CatalogTableRow>[] => {
  return [
    {
      title: 'Environment',
      render: ({ entity }) => (entity as InstanceEntityV1).spec.environment ? (
        <EntityRefLink
          entityRef={parseEntityRef((entity as InstanceEntityV1).spec.environment as string, {
            defaultKind: 'environment'
          })}
          defaultKind="environment"
        />
      ) : undefined,
    },
    {
      title: 'Deployment',
      render: ({ entity }) => (entity as InstanceEntityV1).spec.deployment ? (
        <EntityRefLink
          entityRef={parseEntityRef((entity as InstanceEntityV1).spec.deployment as string, {
            defaultKind: 'deployment'
          })}
          defaultKind="deployment"
        />
      ) : undefined,
    },
    {
      title: 'Instance Of',
      render: ({ entity }) => (entity as InstanceEntityV1).spec.instanceOf ? (
        <EntityRefLink
          entityRef={parseEntityRef((entity as InstanceEntityV1).spec.instanceOf as string, {
            defaultKind: 'component'
          })}
        />
      ) : undefined,
    },
  ];
}


const createEntitySpecificColumns = (kind: string): TableColumn<CatalogTableRow>[] => {
  switch (kind) {
    case 'instance':
      return createInstanceEntitySpecificColumns();
    case 'deployment':
      return createDeploymentEntitySpecificColumns();
    case 'environment':
      return createEnvironmentEntitySpecificColumns();
    default:
      return [];
  }
}

export const bongoCatalogColumns = (kind: string | undefined): TableColumn<CatalogTableRow>[] | undefined => {
  if (kind === undefined) return undefined;

  return ['environment', 'environmentgroup', 'deployment', 'instance'].includes(kind) ? [
    CatalogTable.columns.createNameColumn({ defaultKind: kind }),
    ...createEntitySpecificColumns(kind),
    CatalogTable.columns.createMetadataDescriptionColumn(),
    CatalogTable.columns.createTagsColumn(),
  ] : undefined;
}