import { Entity } from '@backstage/catalog-model';
import { Typography } from '@material-ui/core';
import {
  useEntity,
  useRelatedEntities,
} from '@backstage/plugin-catalog-react';
import React from 'react';
import {
  InfoCard,
  InfoCardVariants,
  Link,
  Progress,
  ResponseErrorPanel,
  TableColumn,
} from '@backstage/core-components';
import { BongoEntityTable } from '../BongoEntityTable';

/** @public */
export type BongoRelatedEntitiesCardProps<T extends Entity> = {
  variant?: InfoCardVariants;
  title: string;
  columns: TableColumn<T>[];
  entityKind?: string;
  relationType: string;
  emptyMessage: string;
  emptyHelpLink: string;
  asRenderableEntities: (entities: Entity[]) => T[];
  paging: boolean;
  search: boolean;
};

/**
 * Bongo's version of {@link RelatedEntitiesCard}. Adds some options like search and pagination. 
 *
 */
export function BongoRelatedEntitiesCard<T extends Entity>(
  props: BongoRelatedEntitiesCardProps<T>,
) {
  const {
    variant = 'gridItem',
    title,
    columns,
    entityKind,
    relationType,
    emptyMessage,
    emptyHelpLink,
    asRenderableEntities,
    paging,
    search
  } = props;

  const { entity } = useEntity();
  const { entities, loading, error } = useRelatedEntities(entity, {
    type: relationType,
    kind: entityKind,
  });

  if (loading) {
    return (
      <InfoCard variant={variant} title={title}>
        <Progress />
      </InfoCard>
    );
  }

  if (error) {
    return (
      <InfoCard variant={variant} title={title}>
        <ResponseErrorPanel error={error} />
      </InfoCard>
    );
  }

  return (
    <BongoEntityTable
      title={title}
      variant={variant}
      emptyContent={
        <div style={{ textAlign: 'center' }}>
          <Typography variant="body1">{emptyMessage}</Typography>
          <Typography variant="body2">
            <Link to={emptyHelpLink}>Learn how to change this.</Link>
          </Typography>
        </div>
      }
      columns={columns}
      entities={asRenderableEntities(entities || [])}
      search={search}
      paging={paging}
    />
  );
}
