import { EnvironmentEntityV1 } from '../kinds/EnvironmentEntityV1'

export const environmentEntityV1Example: EnvironmentEntityV1 = {
  'apiVersion': 'bongo/v1',
  'kind': 'Environment',
  'metadata': {
    'name': 'bongo-na',
    'description': 'bongo-na environment.'
  },
  'spec': {
    'aliases': ['ysu-na'],
    'domain': 'bongo-na.youseeu.com',
    'additional_domains': ['ysu-na.youseeu.com'],
    'stage': 'production',
    'aws_account': 'ysu-vail',
    'aws_region': 'us-east-1',
    'memberOf': ['production'],
    'targetOf': 'deployment-22.4',
    'instanceOf': 'bongo'
  }
}