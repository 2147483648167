import {
  Entity,
  entityKindSchemaValidator,
  KindValidator,
} from '@backstage/catalog-model';
import schema from '../schema/Environment.v1.schema.json';

/**
 * Backstage catalog Component kind Environment. An environment is an instance of a domain (product).
 *
 * @remarks
 *
 * See {@link https://backstage.io/docs/features/software-catalog/system-model}
 *
 * @public
 */
export interface EnvironmentEntityV1 extends Entity {
  apiVersion: 'bongo/v1';
  kind: 'Environment';
  spec: {
    aliases?: string[];
    domain?: string;
    additional_domains?: string[];
    stage?: string;
    aws_account?: string;
    aws_region?: string;
    memberOf?: string[];
    targetOf?: string;
    instanceOf: string;
  };
}

const validator = entityKindSchemaValidator(schema);

/**
 * Entity data validator for {@link EnvironmentEntityV1}.
 *
 * @public
 */
export const environmentEntityV1Validator: KindValidator = {
  // TODO(freben): Emulate the old KindValidator until we fix that type
  async check(data: Entity) {
    return validator(data) === data;
  },
};
