import React from 'react'
import { AboutField } from '@backstage/plugin-catalog';
import { EntityRefLink, useEntity } from '@backstage/plugin-catalog-react';
import { InstanceEntityV1 } from '@internal/plugin-bongo-common';
import { parseEntityRef } from '@backstage/catalog-model';

export const InstanceEntityAboutCardContent = () => {
  const { entity } = useEntity();

  return (
    <>
      <AboutField label="Instance Of" gridSizes={{ xs: 12, sm: 6, lg: 4 }}>
        <EntityRefLink
          entityRef={parseEntityRef((entity as InstanceEntityV1).spec.instanceOf)}
        />
      </AboutField>

      <AboutField label="Environment" value="No Environment" gridSizes={{ xs: 12, sm: 6, lg: 4 }}>
        {(entity as InstanceEntityV1).spec.environment ? <EntityRefLink
          entityRef={parseEntityRef((entity as InstanceEntityV1).spec.environment as string, {
            defaultKind: 'environment'
          })}
        /> : undefined}
      </AboutField>

      <AboutField label="Deployment" value="No Deployment" gridSizes={{ xs: 12, sm: 6, lg: 4 }}>
        {(entity as InstanceEntityV1).spec.deployment ? <EntityRefLink
          entityRef={parseEntityRef((entity as InstanceEntityV1).spec.deployment as string, {
            defaultKind: 'deployment'
          })}
        /> : undefined}
      </AboutField>
    </>
  );
}