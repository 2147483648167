import React, { useCallback } from 'react';
import { Chip, Grid } from '@material-ui/core';
import { AboutField } from '@backstage/plugin-catalog';
import {
  useEntity,
  catalogApiRef,
} from '@backstage/plugin-catalog-react';
import { useApi, alertApiRef } from '@backstage/core-plugin-api';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  makeStyles,
  Typography
} from '@material-ui/core';
import {
  stringifyEntityRef,
  ANNOTATION_LOCATION
} from '@backstage/catalog-model';
import CachedIcon from '@material-ui/icons/Cached';
import { AboutCardAnnotations } from './AboutCardAnnotations';
import { EnvironmentEntityAboutCardContent } from './EnvironmentEntityAboutCardContent';
import { DeploymentEntityAboutCardContent } from './DeploymentEntityAboutCardContent';
import { InstanceEntityAboutCardContent } from './InstanceEntityAboutCardContent';

// NOTE: Much of the functionality and structure is copied from backstage's EntityAboutCard 
// source for EntityAboutCard is here https://github.com/backstage/backstage/blob/master/plugins/catalog/src/components/AboutCard/AboutCard.tsx 

const useStyles = makeStyles({
  gridItemCard: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100% - 10px)', // for pages without content header
    marginBottom: '10px',
  },
  gridItemCardContent: {
    flex: 1,
  },
  description: {
    wordBreak: 'break-word'
  }
})


// the normal EntityAboutCard only works with builtin types, so we make our own
/** @public */
export const BongoEntityAboutCard = () => {
  const classes = useStyles();
  const { entity } = useEntity();
  const catalogApi = useApi(catalogApiRef);
  const alertApi = useApi(alertApiRef);

  const entityLocation = entity.metadata.annotations?.[ANNOTATION_LOCATION];
  const allowRefresh =
    entityLocation?.startsWith('url:') || entityLocation?.startsWith('file:');
  const refreshEntity = useCallback(async () => {
    await catalogApi.refreshEntity(stringifyEntityRef(entity));
    alertApi.post({ message: 'Refresh scheduled', severity: 'info' });
  }, [catalogApi, alertApi, entity]);

  const isEnvironment = entity.kind.toLocaleLowerCase('en-US') === 'environment';
  const isDeployment = entity.kind.toLocaleLowerCase('en-US') === 'deployment';
  const isInstance = entity.kind.toLocaleLowerCase('en-US') === 'instance';


  return (
    <Card className={classes.gridItemCard}>
      <CardHeader
        title="About"
        action={
          <>
            {allowRefresh && (
              <IconButton
                aria-label="Refresh"
                title="Schedule entity refresh"
                onClick={refreshEntity}
              >
                <CachedIcon />
              </IconButton>
            )}
          </>
        }
      />
      <br />
      <Divider />
      <CardContent className={classes.gridItemCardContent}>
        <Grid container>
          <AboutField label="Description" gridSizes={{ xs: 12 }}>
            <Typography variant="body2" paragraph className={classes.description}>
              {entity?.metadata?.description || 'No description'}
            </Typography>
          </AboutField>
          {(isEnvironment) && <EnvironmentEntityAboutCardContent />}
          {(isDeployment) && <DeploymentEntityAboutCardContent />}
          {(isInstance) && <InstanceEntityAboutCardContent />}
          <AboutCardAnnotations />
          <AboutField
            label="Tags"
            value="No Tags"
            gridSizes={{ xs: 12, sm: 6, lg: 4 }}
          >
            {(entity?.metadata?.tags || []).map(t => (
              <Chip key={t} size="small" label={t} />
            ))}
          </AboutField>
        </Grid>
      </CardContent>
    </Card>
  );
}
