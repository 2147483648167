import React from 'react';
import { useEntity } from '@backstage/plugin-catalog-react';
import { BongoAboutField } from './BongoAboutField';

const BONGO_ANNOTATION_PREFIX = 'bongo/';

/** @public */
export const AboutCardAnnotations = () => {
  const { entity } = useEntity();

  return (
    <>
      {Object.entries(entity.metadata.annotations ?? {})
        .filter(([key, _value]) => key.substring(0, BONGO_ANNOTATION_PREFIX.length) === BONGO_ANNOTATION_PREFIX)
        .map(([key, value]) => (
          <BongoAboutField
            label={key.substring(BONGO_ANNOTATION_PREFIX.length, key.length)}
            emptyValue={`No ${key.substring(BONGO_ANNOTATION_PREFIX.length, key.length)}`}
            value={value}
          />
        ))
      }
    </>
  );
}