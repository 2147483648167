import { AboutField } from '@backstage/plugin-catalog';
import React from 'react';

interface BongoAboutFieldProps {
  label: string;
  value: string | string[] | undefined;
  emptyValue?: string;
}

const stringListToString = (strList: string[] | undefined, defaultStr: string): string => {
  return strList?.join?.(', ') as string | undefined || defaultStr;
}

/** @private */
export const BongoAboutField = ({ label, value, emptyValue }: BongoAboutFieldProps) => {
  const validValue: string | string[] = (value === undefined ? emptyValue : value) || 'undefined';
  const valueStr: string = Array.isArray(validValue) ?
    stringListToString(value as string[], 'No aliases') :
    validValue as string;
  return (
    <AboutField
      label={label}
      value={valueStr}
      gridSizes={{ xs: 12, sm: 6, lg: 4 }}
    />
  );
}