import {
  Entity,
  entityKindSchemaValidator,
  KindValidator,
} from '@backstage/catalog-model';
import schema from '../schema/EnvironmentGroup.v1.schema.json';

/**
 * Backstage catalog Component kind EnvironmentGroup. An EnvironmentGroup is a group of Environment entities.
 *
 * @remarks
 *
 * See {@link https://backstage.io/docs/features/software-catalog/system-model}
 *
 * @public
 */
export interface EnvironmentGroupEntityV1 extends Entity {
  apiVersion: 'bongo/v1';
  kind: 'EnvironmentGroup';
  spec: {
    members?: string[];
  };
}

const validator = entityKindSchemaValidator(schema);

/**
 * Entity data validator for {@link EnvironmentGroupEntityV1}.
 *
 * @public
 */
export const environmentGroupEntityV1Validator: KindValidator = {
  // TODO(freben): Emulate the old KindValidator until we fix that type
  async check(data: Entity) {
    return validator(data) === data;
  },
};
